import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
// import { API, GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import {
  buildDataProvider,
} from "./index";

import awsExports from "../aws-exports";


// const accountId = 'master';

const proxyDataProvider = buildDataProvider('master',
  {
    queries,
    mutations,
  },
  {
    storageBucket: awsExports.aws_user_files_s3_bucket,
    storageRegion: awsExports.aws_user_files_s3_bucket_region,
    enableAdminQueries: true,
  }
);

// async function graphql(
//   query: string,
//   variables: Record<string, unknown>
// ): Promise<any> {
//   const queryResult = <GraphQLResult>await API.graphql({
//     query,
//     variables,
//     authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
//   });

//   if (queryResult.errors || !queryResult.data) {
//     throw new Error("Data provider error");
//   }

//   return queryResult.data;
// }

// function getQuery(queryName: string): any {
//   let q:any = queries;
//   let m: any = mutations;
//     if (q[queryName]) {
//       return q[queryName];
//     }

//     if (m[queryName]) {
//       return m[queryName];
//     }

//     console.log(`Could not find query ${queryName}`);

//     throw new Error("Data provider error");

// }

// function getQueryName(operation: string, resource: string) {
//   const pluralOperations = ["list"];
//   if (pluralOperations.includes(operation)) {
//     return `${operation}${
//       resource.charAt(0).toUpperCase() + resource.slice(1)
//     }`;
//   }
//   // else singular operations ["create", "delete", "get", "update"]
//   return `${operation}${
//     resource.charAt(0).toUpperCase() + resource.slice(1, -1)
//   }`;
// }

// let dataProvider: DataProvider = {
//   getList: proxyDataProvider.getList,
//   getOne: async (resource: string, params: any) => {
//     if (resource == 'cognitoUsers') {
//       return proxyDataProvider.getOne(resource, params);
//     }
//     const queryName = getQueryName("get", resource);
//     const query = getQuery(queryName);
//     let variables: any = {id: params.id};
//     if (resource == 'units') {
//       variables.locationId = params.locationId;
//     }
//     if (resource == 'leads' || resource == 'tenants') {
//       variables.accountId = accountId;
//     }
//     console.log('params', params);
//     console.log('params', variables);
//     // Executes the query
//     const queryData = (await graphql(query, variables))[queryName];

//     return {
//       data: queryData,
//     };
//   },
//   getMany: proxyDataProvider.getMany,
//   getManyReference: proxyDataProvider.getManyReference,
//   create: proxyDataProvider.create,
//   update: proxyDataProvider.update,
//   updateMany: proxyDataProvider.updateMany,
//   delete: proxyDataProvider.delete,
//   deleteMany: proxyDataProvider.deleteMany,
// }

export function getDataProvider() {
  return proxyDataProvider;
}