import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { PaymentTextField } from "./custom-fields";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { useInput } from "react-admin";

type PaymentMethod = "CARD" | "ACH_ECHECK" | "CASH" | "CHECK" | "MONEY_ORDER";

function PaymentFormWithOptions(props: any) {
  const {
    field: { name, value, onChange, ...rest },
  } = useInput({ ...props, type: "radio" });
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValue = (event.target as HTMLInputElement).value;
    props.updateForm(updatedValue);
    onChange(event);
  };
  console.log(name, value);
  return (
    <React.Fragment>
      <FormControl component="fieldset" name={name}>
        <FormLabel component="legend">Select New Payment Method</FormLabel>
        <RadioGroup
          aria-label="payment method"
          value={value}
          // defaultValue={props.defaultValue}
          // name="paymentMethod"
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value="CARD"
            control={<Radio size="small" />}
            label="Debit / Credit"
          />
          <FormControlLabel
            value="ACH_ECHECK"
            control={<Radio size="small" />}
            label="ACH / eCheck"
          />
          <FormControlLabel
            value="CASH"
            control={<Radio size="small" />}
            label="Cash"
          />
          <FormControlLabel
            value="CHECK"
            control={<Radio size="small" />}
            label="Check"
          />
          <FormControlLabel
            value="MONEY_ORDER"
            control={<Radio size="small" />}
            label="Money Order"
          />
        </RadioGroup>
      </FormControl>
    </React.Fragment>
  );
}

export default function PaymentForm() {
  const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethod>(
    "CARD"
  );
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Payment Method
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PaymentFormWithOptions
            id="paymentMethod"
            name="paymentMethod"
            source="paymentMethod"
            updateForm={(v: PaymentMethod) => setPaymentMethod(v)}
            value={paymentMethod}
          />
        </Grid>
        {paymentMethod === "CARD" ? (
          <CardForm />
        ) : paymentMethod === "ACH_ECHECK" ? (
          <ACH_eCheckForm />
        ) : paymentMethod === "CASH" ? (
          <CashForm />
        ) : paymentMethod === "CHECK" ? (
          <CheckForm />
        ) : paymentMethod === "MONEY_ORDER" ? (
          <MoneyOrderForm />
        ) : null}
      </Grid>
    </React.Fragment>
  );
}

function CardForm() {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" color="GrayText">
          Card Information
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <PaymentTextField
          id="cardName"
          name="cardName"
          label="Name on card"
          source="cardName"
          autoComplete="cc-name"
          isRequired={true}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PaymentTextField
          id="cardNumber"
          name="cardNumber"
          label="Card number"
          source="cardNumber"
          autoComplete="cc-number"
          isRequired={true}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PaymentTextField
          id="expDate"
          name="expDate"
          label="Expiry date"
          source="expDate"
          autoComplete="cc-exp"
          isRequired={true}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PaymentTextField
          id="cvv"
          name="cvv"
          label="CVV"
          source="cvv"
          helperText="Last three digits on signature strip"
          autoComplete="cc-csc"
          isRequired={true}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox color="secondary" name="saveCard" value="yes" />}
          label="Remember credit card details for next time"
        />
      </Grid>
    </>
  );
}

function ACH_eCheckForm() {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" color="GrayText">
          ACH / eCheck Information
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <PaymentTextField
          id="accountFirstName"
          name="accountFirstName"
          label="Account holder first name"
          source="accountFirstName"
          // autoComplete=
          isRequired={true}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PaymentTextField
          id="accountLastName"
          name="accountLastName"
          label="Account holder last name"
          source="accountLastName"
          // autoComplete=
          isRequired={true}
        />
      </Grid>
      <Grid item xs={12}>
        <PaymentTextField
          id="accountType"
          name="accountType"
          label="Account type"
          source="accountType"
          // autoComplete
          isRequired={true}
        />
      </Grid>
      <Grid item xs={12}>
        <PaymentTextField
          id="bankName"
          name="bankName"
          label="Bank name"
          source="bankName"
          // autoComplete
          isRequired={true}
        />
      </Grid>
      <Grid item xs={12}>
        <PaymentTextField
          id="routingNumber"
          name="routingNumber"
          label="Routing number"
          source="routingNumber"
          // autoComplete="cc-exp"
          isRequired={true}
        />
      </Grid>
      <Grid item xs={12}>
        <PaymentTextField
          id="accountNumber"
          name="accountNumber"
          label="Account number"
          source="accountNumber"
          // autoComplete="cc-exp"
          isRequired={true}
        />
      </Grid>
      <Grid item xs={12}>
        <PaymentTextField
          id="accountNumberConfirmation"
          name="accountNumberConfirmation"
          label="Account number confirmation"
          source="accountNumberConfirmation"
          // autoComplete="cc-exp"
          isRequired={true}
        />
      </Grid>
    </>
  );
}

function CashForm() {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" color="GrayText">
          CashAmount
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <PaymentTextField
          id="amountTendered"
          name="amountTendered"
          label="Amount Tendered"
          source="amountTendered"
          // autoComplete
          placeholder="$0.00"
          isRequired={true}
        />
      </Grid>
    </>
  );
}

function CheckForm() {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" color="GrayText">
          Check Information
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <PaymentTextField
          id="nameOnCheck"
          name="nameOnCheck"
          label="Name on check"
          source="nameOnCheck"
          // autoComplete
          isRequired={true}
        />
      </Grid>
      <Grid item xs={12}>
        <PaymentTextField
          id="checkNumber"
          name="checkNumber"
          label="Check number"
          source="checkNumber"
          // autoComplete
          isRequired={true}
        />
      </Grid>
      <Grid item xs={12}>
        <PaymentTextField
          id="checkAmount"
          name="checkAmount"
          label="Check amount"
          source="checkAmount"
          // autoComplete
          placeholder="$0.00"
          isRequired={true}
        />
      </Grid>
    </>
  );
}
function MoneyOrderForm() {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" color="GrayText">
          Money Order Information
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <PaymentTextField
          id="referenceNumber"
          name="referenceNumber"
          label="Reference number"
          source="referenceNumber"
          // autoComplete
          isRequired={true}
        />
      </Grid>
      <Grid item xs={12}>
        <PaymentTextField
          id="moneyOrderAmount"
          name="moneyOrderAmount"
          label="Money order amount"
          source="moneyOrderAmount"
          // autoComplete
          placeholder="$0.00"
          isRequired={true}
        />
      </Grid>
    </>
  );
}
