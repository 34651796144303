import { AccountCircle, Phone } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  ReferenceManyField,
  Datagrid,
} from "react-admin";
import ExpandCard from "../move-in/expand-card";

import styles from "./tenant.module.css";

export const TenantShow = (props: any) => {
  return (
    <Show {...props} classes={{ root: styles.overRoot, card: "my-class-ooo" }}>
      <SimpleShowLayout>
        <Grid container columnSpacing={3} rowSpacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                borderBottom: "1px solid #aaa",
                paddingBottom: 1,
              }}
            >
              <AccountCircle fontSize="large" />
              <FullNameField />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <SimpleField source="unitId" label="Unit ID" />
          </Grid>
          {/* <Grid item xs={12}>
            <Typography variant="h6" textAlign="center">Personal Information</Typography>
          </Grid> */}
          <Grid sm={6} item xs={12}>
            <AddressField />
          </Grid>
          <Grid sm={6} item xs={12}>
            <SimpleField source="email" label="Email" />
          </Grid>
          <Grid sm={6} item xs={12}>
            <SimpleField source="companyName" label="Company Name" />
          </Grid>
          <Grid item sm={6} xs={12}>
            <PhoneField />
          </Grid>
          <Grid sm={6} item xs={12}>
            <SimpleField
              source="driverLicenseNumber"
              label="Driver License Number"
            />
          </Grid>
          <Grid sm={6} item xs={12}>
            <CustomDateField source="customerSince" label="Customer Since" />
          </Grid>
          <Grid item xs={12}>
            <ExpandCard title="Payments">
              <ReferenceManyField
                reference="payments"
                target="paymentsByCustomerId.customerId"
                label="Payments"
                perPage={10}
                filter={{}}
              >
                <Datagrid>
                  <CustomDateField source="paymentDate" sortable={false} />
                  <TextField source="amountDue" sortable={false} />
                  <TextField source="paymentAmount" sortable={false} />
                </Datagrid>
              </ReferenceManyField>
            </ExpandCard>
          </Grid>
          {/* <DateField source="createdAt" />
          <DateField source="updatedAt" /> */}
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};

function SimpleField(props: any) {
  const { source, label } = props;
  const record = useRecordContext();
  return (
    <Box>
      {label ? (
        <Typography
          variant="body2"
          sx={{ color: "GrayText", fontWeight: "100" }}
        >
          {label}
        </Typography>
      ) : null}
      <Typography variant="body1">{record[source] || "- - -"}</Typography>
    </Box>
  );
}

function CustomDateField(props: any) {
  const { source, label } = props;
  const record = useRecordContext();
  const d = new Date(record[source]);
  const currentMonth = d.getMonth() + 1;
  const currentDay = d.getDate();
  const customDate = [
    currentMonth < 10 ? "0" + currentMonth : currentMonth,
    currentDay < 10 ? "0" + currentDay : currentDay,
    d.getFullYear(),
  ].join(" / ");
  return (
    <Box>
      {label ? (
        <Typography
          variant="body2"
          sx={{ color: "GrayText", fontWeight: "100" }}
        >
          {label}
        </Typography>
      ) : null}
      <Typography variant="body1">
        {record[source] ? customDate : "- - -"}
      </Typography>
    </Box>
  );
}

function FullNameField() {
  const record = useRecordContext();
  return (
    <Typography
      variant="h5"
      sx={{ marginLeft: 1, fontWeight: "100" }}
    >{`${record.firstName} ${record.lastName}`}</Typography>
  );
}

function AddressField() {
  const record = useRecordContext();
  const { address1, address2, city, state, zipCode } = record.address;
  return (
    <Box>
      <Typography variant="body2" sx={{ color: "GrayText", fontWeight: "100" }}>
        Address
      </Typography>
      <Typography variant="body1">{`${address1}, ${city}, ${state}, ${zipCode}`}</Typography>
    </Box>
  );
}

function PhoneField() {
  const record = useRecordContext();
  const phones = record.phones.map((phone: any, i: number) => {
    const { number, extension, type } = phone;
    return (
      <Typography key={`phone-${i}`} variant="body1">
        •{" "}
        {`${
          extension ? extension + " " : ""
        }${number} (${(type as string).toLowerCase()})`}
      </Typography>
    );
  });
  return (
    <Box>
      <Typography variant="body2" sx={{ color: "GrayText", fontWeight: "100" }}>
        Phones
      </Typography>
      {/* <Phone fontSize="small" sx={{ color: "GrayText" }} /> */}
      {phones}
    </Box>
  );
}

/* 
type Tenant
  @model
  @auth(rules: [
    { allow: groups, groups: ["admin"] }
  ])
  @key(fields: ["accountId", "id"])
  @key(name: "byVacateDate", fields: ["vacateDate"], queryField: "tenantsByVacateDate") {
  id: ID!
  accountId: ID!
  locationId: ID!
  address: Address
  companyName: String
  phones: [Phone]
  firstName: String
  lastName: String
  deposit: Float
  unitId: ID
  notes: String
  customerSince: AWSDateTime
  driverLicenseNumber: String
  vacateDate: AWSDateTime
  gateAccessCode: String
  moneyOwed: Float
  email: String
  ssn: String
  billing: [Billing]
  ordersByDate: [Order] @connection(keyName: "byTenantByDate", fields: ["id"])
  ordersByStatusDate: [Order] @connection(keyName: "byTenantByStatusByDate", fields: ["id"])
}
*/
