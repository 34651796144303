import { TextField } from "@mui/material";
import { useInput } from "react-admin";

export const BoundedTextField = (props: any) => {
  const {
    field: { name, onChange, ...rest },
    fieldState: { isTouched, error },
    isRequired,
  } = useInput(props);

  return (
    <TextField
      name={name}
      label={props.label}
      onChange={onChange}
      error={!!(isTouched && error)}
      helperText={isTouched && error}
      required={isRequired}
      variant="standard"
      fullWidth
      placeholder={props.placeholder || ""}
      {...rest}
    />
  );
};
export const PaymentTextField = (props: any) => {
  const {
    field: { name, onChange, ...rest },
    fieldState: { isTouched, error },
    isRequired,
  } = useInput(props);

  return (
    <TextField
      name={name}
      label={props.label}
      onChange={onChange}
      error={!!(isTouched && error)}
      helperText={isTouched && error}
      required={isRequired}
      fullWidth
      placeholder={props.placeholder || ""}
      {...rest}
    />
  );
};
