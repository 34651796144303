/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      tenantID
      accountRepresentativeID
      productID
      status
      amount
      date
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantID
        accountRepresentativeID
        productID
        status
        amount
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($accountId: ID!, $id: ID!) {
    getPayment(accountId: $accountId, id: $id) {
      id
      accountId
      locationId
      customerId
      paymentDate
      paymentAmount
      unitId
      amountDue
      paymentType
      checkNumber
      createdAt
      updatedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $accountId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPayments(
      accountId: $accountId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountId
        locationId
        customerId
        paymentDate
        paymentAmount
        unitId
        amountDue
        paymentType
        checkNumber
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTenant = /* GraphQL */ `
  query GetTenant($accountId: ID!, $id: ID!) {
    getTenant(accountId: $accountId, id: $id) {
      id
      accountId
      locationId
      address {
        address1
        address2
        city
        state
        zipCode
      }
      companyName
      phones {
        number
        extension
        type
      }
      firstName
      lastName
      deposit
      unitId
      notes
      customerSince
      driverLicenseNumber
      vacateDate
      gateAccessCode
      moneyOwed
      email
      ssn
      billing {
        creditCard {
          card
          cvv
          exp
          alias
        }
      }
      paymentsByDate {
        items {
          id
          accountId
          locationId
          customerId
          paymentDate
          paymentAmount
          unitId
          amountDue
          paymentType
          checkNumber
          createdAt
          updatedAt
        }
        nextToken
      }
      ordersByStatusDate {
        items {
          id
          tenantID
          accountRepresentativeID
          productID
          status
          amount
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $accountId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTenants(
      accountId: $accountId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountId
        locationId
        address {
          address1
          address2
          city
          state
          zipCode
        }
        companyName
        phones {
          number
          extension
          type
        }
        firstName
        lastName
        deposit
        unitId
        notes
        customerSince
        driverLicenseNumber
        vacateDate
        gateAccessCode
        moneyOwed
        email
        ssn
        paymentsByDate {
          nextToken
        }
        ordersByStatusDate {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLead = /* GraphQL */ `
  query GetLead($accountId: ID!, $id: ID!) {
    getLead(accountId: $accountId, id: $id) {
      id
      accountId
      locationId
      firstName
      lastName
      phoneNumber
      desiredUnitType
      desiredMovinDate
      email
      accountRepresentativeID
      source
      createdAt
      updatedAt
    }
  }
`;
export const listLeads = /* GraphQL */ `
  query ListLeads(
    $accountId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLeads(
      accountId: $accountId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountId
        locationId
        firstName
        lastName
        phoneNumber
        desiredUnitType
        desiredMovinDate
        email
        accountRepresentativeID
        source
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      name
      startDate
      phoneNumber
      facilityID
      jobTitle
      newHire
      createdAt
      updatedAt
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        startDate
        phoneNumber
        facilityID
        jobTitle
        newHire
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUnit = /* GraphQL */ `
  query GetUnit($locationId: ID!, $id: ID!) {
    getUnit(locationId: $locationId, id: $id) {
      locationId
      id
      tenantId
      name
      tenant {
        id
        accountId
        locationId
        address {
          address1
          address2
          city
          state
          zipCode
        }
        companyName
        phones {
          number
          extension
          type
        }
        firstName
        lastName
        deposit
        unitId
        notes
        customerSince
        driverLicenseNumber
        vacateDate
        gateAccessCode
        moneyOwed
        email
        ssn
        paymentsByDate {
          nextToken
        }
        ordersByStatusDate {
          nextToken
        }
        createdAt
        updatedAt
      }
      width
      length
      height
      status
      size
      rate
      yearlyRent
      previousOccupiedDate
      vacantDate
      estimatedMoveOutDate
      createdAt
      updatedAt
    }
  }
`;
export const listUnits = /* GraphQL */ `
  query ListUnits(
    $locationId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelUnitFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUnits(
      locationId: $locationId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        locationId
        id
        tenantId
        name
        tenant {
          id
          accountId
          locationId
          companyName
          firstName
          lastName
          deposit
          unitId
          notes
          customerSince
          driverLicenseNumber
          vacateDate
          gateAccessCode
          moneyOwed
          email
          ssn
          createdAt
          updatedAt
        }
        width
        length
        height
        status
        size
        rate
        yearlyRent
        previousOccupiedDate
        vacantDate
        estimatedMoveOutDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($accountId: ID!, $id: ID!) {
    getLocation(accountId: $accountId, id: $id) {
      id
      accountId
      name
      address
      phoneNumber
      employees {
        items {
          id
          name
          startDate
          phoneNumber
          facilityID
          jobTitle
          newHire
          createdAt
          updatedAt
        }
        nextToken
      }
      units {
        items {
          locationId
          id
          tenantId
          name
          width
          length
          height
          status
          size
          rate
          yearlyRent
          previousOccupiedDate
          vacantDate
          estimatedMoveOutDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $accountId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLocations(
      accountId: $accountId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountId
        name
        address
        phoneNumber
        employees {
          nextToken
        }
        units {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccountRepresentative = /* GraphQL */ `
  query GetAccountRepresentative($id: ID!) {
    getAccountRepresentative(id: $id) {
      id
      orders {
        items {
          id
          tenantID
          accountRepresentativeID
          productID
          status
          amount
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      orderTotal
      salesPeriod
      createdAt
      updatedAt
    }
  }
`;
export const listAccountRepresentatives = /* GraphQL */ `
  query ListAccountRepresentatives(
    $filter: ModelAccountRepresentativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountRepresentatives(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orders {
          nextToken
        }
        orderTotal
        salesPeriod
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      price
      orders {
        items {
          id
          tenantID
          accountRepresentativeID
          productID
          status
          amount
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        price
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ordersByTenantByStatusByDate = /* GraphQL */ `
  query OrdersByTenantByStatusByDate(
    $tenantID: ID
    $statusDate: ModelOrderByTenantByStatusByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByTenantByStatusByDate(
      tenantID: $tenantID
      statusDate: $statusDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantID
        accountRepresentativeID
        productID
        status
        amount
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ordersByTenantByDate = /* GraphQL */ `
  query OrdersByTenantByDate(
    $tenantID: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByTenantByDate(
      tenantID: $tenantID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantID
        accountRepresentativeID
        productID
        status
        amount
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ordersByRepresentativeByDate = /* GraphQL */ `
  query OrdersByRepresentativeByDate(
    $accountRepresentativeID: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByRepresentativeByDate(
      accountRepresentativeID: $accountRepresentativeID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantID
        accountRepresentativeID
        productID
        status
        amount
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ordersByProduct = /* GraphQL */ `
  query OrdersByProduct(
    $productID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByProduct(
      productID: $productID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantID
        accountRepresentativeID
        productID
        status
        amount
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const paymentsByCustomerId = /* GraphQL */ `
  query PaymentsByCustomerId(
    $customerId: ID
    $paymentDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentsByCustomerId(
      customerId: $customerId
      paymentDate: $paymentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        locationId
        customerId
        paymentDate
        paymentAmount
        unitId
        amountDue
        paymentType
        checkNumber
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tenantsByVacateDate = /* GraphQL */ `
  query TenantsByVacateDate(
    $vacateDate: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantsByVacateDate(
      vacateDate: $vacateDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        locationId
        address {
          address1
          address2
          city
          state
          zipCode
        }
        companyName
        phones {
          number
          extension
          type
        }
        firstName
        lastName
        deposit
        unitId
        notes
        customerSince
        driverLicenseNumber
        vacateDate
        gateAccessCode
        moneyOwed
        email
        ssn
        paymentsByDate {
          nextToken
        }
        ordersByStatusDate {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const employeesNewHire = /* GraphQL */ `
  query EmployeesNewHire(
    $newHire: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeesNewHire(
      newHire: $newHire
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        startDate
        phoneNumber
        facilityID
        jobTitle
        newHire
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const employeesNewHireByStartDate = /* GraphQL */ `
  query EmployeesNewHireByStartDate(
    $newHire: String
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeesNewHireByStartDate(
      newHire: $newHire
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        startDate
        phoneNumber
        facilityID
        jobTitle
        newHire
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const employeesByName = /* GraphQL */ `
  query EmployeesByName(
    $name: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeesByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        startDate
        phoneNumber
        facilityID
        jobTitle
        newHire
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const employeesByJobTitle = /* GraphQL */ `
  query EmployeesByJobTitle(
    $jobTitle: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeesByJobTitle(
      jobTitle: $jobTitle
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        startDate
        phoneNumber
        facilityID
        jobTitle
        newHire
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const employeesByFacility = /* GraphQL */ `
  query EmployeesByFacility(
    $facilityID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeesByFacility(
      facilityID: $facilityID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        startDate
        phoneNumber
        facilityID
        jobTitle
        newHire
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const unitsByStatus = /* GraphQL */ `
  query UnitsByStatus(
    $status: UnitStatus
    $sortDirection: ModelSortDirection
    $filter: ModelUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    unitsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        locationId
        id
        tenantId
        name
        tenant {
          id
          accountId
          locationId
          companyName
          firstName
          lastName
          deposit
          unitId
          notes
          customerSince
          driverLicenseNumber
          vacateDate
          gateAccessCode
          moneyOwed
          email
          ssn
          createdAt
          updatedAt
        }
        width
        length
        height
        status
        size
        rate
        yearlyRent
        previousOccupiedDate
        vacantDate
        estimatedMoveOutDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const repsByPeriodAndTotal = /* GraphQL */ `
  query RepsByPeriodAndTotal(
    $salesPeriod: String
    $orderTotal: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountRepresentativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    repsByPeriodAndTotal(
      salesPeriod: $salesPeriod
      orderTotal: $orderTotal
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orders {
          nextToken
        }
        orderTotal
        salesPeriod
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productsByName = /* GraphQL */ `
  query ProductsByName(
    $name: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        price
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      password
      picture {
        bucket
        region
        key
      }
      documents {
        bucket
        region
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        password
        picture {
          bucket
          region
          key
        }
        documents {
          bucket
          region
          key
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
