import PersonIcon from "@mui/icons-material/Person";
import ManageSearch from "@mui/icons-material/ManageSearch";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DomainIcon from "@mui/icons-material/Domain";
import WorkIcon from "@mui/icons-material/Work";
import StarIcon from "@mui/icons-material/Star";
import PaidIcon from "@mui/icons-material/Paid";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssistantIcon from "@mui/icons-material/Assistant";
import ViewList from "@mui/icons-material/ViewList";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const DEFAULT_FONT_SIZE = "large";
const DEFAULT_COLOR = "secondary";

export const Dashboard = () => {
  return <DashboardIcon fontSize={DEFAULT_FONT_SIZE} color={DEFAULT_COLOR} />;
};

export const Orders = () => {
  return <ListAltIcon fontSize={DEFAULT_FONT_SIZE} color={DEFAULT_COLOR} />;
};

export const Leads = () => {
  return <ManageSearch  fontSize={DEFAULT_FONT_SIZE} color={DEFAULT_COLOR} />;
};

export const Tenants = () => {
  return <PersonIcon fontSize={DEFAULT_FONT_SIZE} color={DEFAULT_COLOR} />;
};

export const Units = () => {
  return <DomainIcon fontSize={DEFAULT_FONT_SIZE} color={DEFAULT_COLOR} />;
};

export const Employees = () => {
  return <WorkIcon fontSize={DEFAULT_FONT_SIZE} color={DEFAULT_COLOR} />;
};

export const Locations = () => {
  return <StarIcon fontSize={DEFAULT_FONT_SIZE} color={DEFAULT_COLOR} />;
};

export const AccountReps = () => {
  return (
    <SupervisorAccountIcon fontSize={DEFAULT_FONT_SIZE} color={DEFAULT_COLOR} />
  );
};
export const Products = () => {
  return <AssistantIcon fontSize={DEFAULT_FONT_SIZE} color={DEFAULT_COLOR} />;
};

export const DefaultViewList = () => {
  return <ViewList fontSize={DEFAULT_FONT_SIZE} color={DEFAULT_COLOR} />;
};

export const TestLogo = () => {
  return <LogoIconFill fontSize={"medium"} color={DEFAULT_COLOR} />;
};

export const Payments = () => {
  return <PaidIcon fontSize={DEFAULT_FONT_SIZE} color={DEFAULT_COLOR} />;
};

export function LogoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M15.7,0c-2.6,0-5,1.1-6.5,3.3c-1.6,2.4-1.5,5.5-0.4,8.1c0.1-0.3,0.2-0.7,0.3-1C8,11,6.9,11.7,5.8,12.4
				c-0.2,0.1-0.4,0.4-0.4,0.6c0.1,1,0.1,2,0.2,3c0.2-0.2,0.5-0.5,0.8-0.7c-1.1,0.1-2.1,0.2-3.2,0.2c-0.4,0-0.8,0.3-0.8,0.8
				c0.1,1.3,0.1,2.5,0.2,3.8c0.2-0.2,0.5-0.5,0.8-0.8c-0.9,0-1.7,0.1-2.6,0.1c-0.4,0-0.8,0.3-0.8,0.8c0.1,1.2,0,2.5,0.2,3.6
				c0.3,1.7,2.3,1.7,3.7,1.6c0.7-0.1,1.3-0.8,1.8-1.2c1.1-1,2.2-2,3.3-3c1.3-1.2,2.6-2.3,3.8-3.5c0.2-0.2,2-1.5,2-1.8c0,0,0,0,0,0
				c0.1-0.1,0-0.1-0.5,0.2c0,0,0.6,0,0.6,0c0.4,0,0.8,0,1.3,0c1-0.1,2-0.4,2.9-0.9c1.8-1,3.4-2.6,4.2-4.6C25.3,5.4,20.9,0.1,15.7,0
				c-1,0-1,1.5,0,1.5c4.4,0.1,8.1,4.8,6,9c-0.9,1.8-2.6,3.2-4.4,3.8c-0.8,0.3-1.5,0.3-2.3,0.3c-0.5,0-0.8,0-1.3,0.2
				c-0.6,0.4-1.1,1-1.6,1.4c-1,0.9-2,1.8-3,2.8c-1.8,1.6-3.7,3.2-5.4,4.9c-0.1,0.1-0.2,0.1-0.2,0.2c0,0.1,0.7,0,0.2-0.2
				c-0.5-0.1-1.3,0.2-1.8,0c-0.6-0.3-0.4-1.5-0.4-2c0-0.6-0.1-1.1-0.1-1.7C1.3,20.5,1,20.8,0.8,21c0.9,0,1.7-0.1,2.6-0.1
				c0.4,0,0.8-0.3,0.8-0.8C4,18.9,4,17.6,3.9,16.3c-0.2,0.2-0.5,0.5-0.8,0.8c1.1-0.1,2.1-0.2,3.2-0.2c0.4,0,0.8-0.3,0.8-0.8
				c-0.1-1-0.1-2-0.2-3c-0.1,0.2-0.2,0.4-0.4,0.6c1.1-0.7,2.2-1.4,3.4-2.1c0.4-0.2,0.4-0.6,0.3-1c-1.9-4.4,0.7-9,5.6-9.1
				C16.7,1.5,16.7,0,15.7,0z"
      />
      <path
        d="M6.6,21.1c1.7-1.9,3.3-3.8,5-5.8c0.2-0.3,0.5-0.5,0.7-0.8c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0
				c-1.7,1.9-3.3,3.8-5,5.8C6,19.5,5.7,19.8,5.5,20c-0.3,0.3-0.3,0.8,0,1.1C5.8,21.4,6.3,21.4,6.6,21.1c1.7-1.9,3.3-3.8,5-5.8
				c0.2-0.3,0.5-0.5,0.7-0.8c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0c-1.7,1.9-3.3,3.8-5,5.8C6,19.5,5.7,19.8,5.5,20
				c-0.3,0.3-0.3,0.8,0,1.1C5.8,21.4,6.3,21.4,6.6,21.1z"
      />
      <path
        d="M16.9,8.3c-0.1,0-0.1,0-0.2,0c-0.1,0,0,0,0,0c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1,0-0.2-0.1c0,0,0.1,0.1,0,0
				C16.1,8.1,16,8.1,15.9,8c-0.1-0.1,0,0.1,0,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.2,0,0c-0.1-0.1-0.1-0.2-0.2-0.3
				c0,0,0-0.1-0.1-0.1c0-0.1,0-0.1,0,0c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.3c0,0,0-0.2,0,0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3
				c0-0.1,0,0.2,0,0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0,0,0.1-0.1,0,0c0-0.1,0.1-0.2,0.1-0.3c0,0,0.1-0.1,0.1-0.1
				c0.1-0.1-0.1,0.1,0,0c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0.1,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1-0.1c0,0,0.2-0.1,0,0
				c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0-0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0,0,0.2,0,0.1,0c0,0,0.1,0,0.1,0
				c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1-0.1-0.1,0,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0,0c0.1,0.1,0.2,0.1,0.2,0.2
				c0,0,0.1,0.1,0.1,0.1c-0.1-0.1,0,0.1,0,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0,0-0.1-0.1,0,0c0,0.1,0,0.1,0.1,0.2c0,0,0,0.1,0,0.1
				c0,0,0,0.2,0,0.1c0-0.1,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.2,0-0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.4
				c-0.1,0.2,0.1-0.1,0,0c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0.1-0.1,0.1-0.1,0,0C18,7.8,18,7.9,17.9,8
				c-0.1,0.1,0.1-0.1,0,0c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1,0.1,0,0,0c-0.1,0-0.2,0.1-0.4,0.1c0,0-0.1,0,0,0
				C17.1,8.3,17,8.3,16.9,8.3c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8c1.3,0,2.4-0.8,2.9-2c0.4-1.2,0.1-2.6-0.9-3.4
				c-1-0.8-2.4-1-3.6-0.3c-1.1,0.7-1.7,2-1.4,3.3c0.3,1.4,1.6,2.4,3,2.5c0.4,0,0.8-0.3,0.8-0.8C17.6,8.7,17.3,8.3,16.9,8.3z"
      />
    </SvgIcon>
  );
}
export function LogoIconFill(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M15.7,0c-2.6,0-5,1.1-6.5,3.3c-1.5,2.2-1.5,5-0.7,7.4c-0.9,0.6-1.9,1.2-2.8,1.7c-0.2,0.1-0.4,0.4-0.4,0.6
		c0,0.8,0.1,1.6,0.1,2.3c-0.8,0.1-1.6,0.1-2.4,0.2c-0.4,0-0.8,0.3-0.8,0.8c0,1,0.1,2.1,0.1,3.1c-0.6,0-1.2,0.1-1.8,0.1
		c-0.4,0-0.8,0.3-0.8,0.8c0.1,1.2,0,2.5,0.2,3.6c0.3,1.7,2.3,1.7,3.7,1.6c0.7-0.1,1.3-0.8,1.8-1.2c1.1-1,2.2-2,3.3-3
		c1.3-1.2,2.6-2.3,3.8-3.5c0.2-0.2,1.5-1.2,1.9-1.6c0.1,0,0.3,0,0.3,0c0.4,0,0.8,0,1.3,0c1-0.1,2-0.4,2.9-0.9c1.8-1,3.4-2.6,4.2-4.6
		C25.3,5.4,20.9,0.1,15.7,0z M12.3,14.5c-0.2,0.3-0.5,0.5-0.7,0.8c-1.7,1.9-3.3,3.8-5,5.8c-0.3,0.3-0.8,0.3-1.1,0
		c-0.3-0.3-0.3-0.8,0-1.1c0.2-0.3,0.5-0.5,0.7-0.8c1.7-1.9,3.3-3.8,5-5.8c0.3-0.3,0.8-0.3,1.1,0C12.6,13.8,12.5,14.2,12.3,14.5z
		 M16.2,10.5c-1.7,0-3-1.4-3-3s1.4-3,3-3s3,1.4,3,3S17.9,10.5,16.2,10.5z"
      />
      <path d="M15.9,5.5C15.9,5.5,15.9,5.5,15.9,5.5C15.9,5.5,15.8,5.6,15.9,5.5z" />
      <path d="M15.4,6.1C15.5,6,15.4,6.1,15.4,6.1L15.4,6.1z" />
    </SvgIcon>
  );
}
export function CompleteLogo(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M78.9,15.1c-0.9,0.9-1.9,1.2-3,1c-1.7-0.3-2.9-1.7-3-3.5c-0.1-0.9,0-1.8,0.4-2.6c1-2,3.4-2.6,5.3-1.3
	c0.1,0.1,0.2,0.1,0.3,0.2c0-0.1,0-0.2,0.1-0.3c0.2-0.5,0.5-0.7,1-0.6c0.5,0.1,0.7,0.4,0.7,0.8c0,2.4,0,4.9,0,7.3
	c0,1.3-1.1,2.3-2.5,2.7c-1.2,0.3-2.4,0.1-3.6-0.2c-0.3-0.1-0.7-0.4-0.8-0.6c-0.1-0.3,0-0.7,0.2-0.9c0.2-0.2,0.6-0.2,0.9-0.2
	c0.8,0.1,1.5,0.4,2.3,0.4C78.5,17.2,79,16.5,78.9,15.1z M79,12c0-1.5-1.1-2.5-2.4-2.4c-1.3,0.2-2.1,1.3-1.9,2.7
	c0.1,1.2,1.1,2.1,2.2,2.1C78.1,14.4,79,13.4,79,12z"
      />
      <path
        d="M37.8,5.5C39,5.6,40,5.9,40.9,6.6c0.2,0.2,0.4,0.6,0.3,1c0,0.2-0.4,0.5-0.6,0.6c-0.2,0.1-0.6-0.1-0.8-0.2
	c-0.8-0.4-1.6-0.8-2.5-0.6c-0.3,0-0.6,0.1-0.8,0.3c-0.3,0.2-0.6,0.6-0.6,0.9c0,0.3,0.3,0.8,0.6,1c0.6,0.3,1.2,0.4,1.8,0.6
	c0.5,0.1,1,0.3,1.5,0.4c1.1,0.4,1.7,1.2,1.7,2.3c0.1,1.2-0.3,2.1-1.3,2.7c-1.7,1.1-4.2,0.9-5.8-0.4c-0.5-0.4-0.6-1-0.3-1.4
	c0.3-0.4,0.8-0.5,1.4,0c1.1,0.8,2.2,1.1,3.5,0.5c0.3-0.2,0.6-0.5,0.7-0.9c0.1-0.5-0.2-0.9-0.6-1.1c-0.7-0.3-1.4-0.4-2.1-0.6
	c-0.4-0.1-0.7-0.2-1.1-0.3c-1.3-0.5-1.9-1.4-1.8-2.8c0-1.2,0.8-2.3,2-2.6C36.7,5.7,37.2,5.7,37.8,5.5z"
      />
      <path
        d="M69.6,8.8c0.1-0.1,0.1-0.2,0.1-0.3c0.2-0.4,0.6-0.6,1-0.5c0.5,0.1,0.7,0.4,0.7,0.9c0,2.1,0,4.2,0,6.2c0,0.4-0.2,0.8-0.7,0.9
	c-0.6,0.1-0.9-0.2-1.1-0.6c-0.6,0.3-1.2,0.6-1.8,0.7c-1.8,0.3-3.2-0.7-3.8-2.4c-0.4-1-0.4-2.1-0.1-3.2C64.7,8.1,67.3,7.2,69.6,8.8z
	 M65.5,12.1c0,1,0.4,1.8,1.2,2.2c0.7,0.3,1.4,0.3,2-0.1c1.3-0.9,1.3-3.2,0-4.1c-0.3-0.2-0.6-0.3-0.9-0.3
	C66.5,9.5,65.5,10.6,65.5,12.1z"
      />
      <path
        d="M56.6,12.1c0,2.4-1.6,4.1-4,4.1c-2.4,0-4.1-1.7-4.1-4.1c0-2.4,1.7-4.1,4-4.1C54.9,8,56.6,9.7,56.6,12.1z M54.7,12.1
	c0-1.4-1-2.4-2.2-2.4c-1.2,0-2.2,1.1-2.2,2.5c0,1.3,1,2.4,2.2,2.4C53.8,14.5,54.7,13.4,54.7,12.1z"
      />
      <path
        d="M89,16.2c-1.3-0.1-2.4-0.3-3.1-1.1c-0.4-0.4-0.4-0.9-0.1-1.2c0.3-0.3,0.8-0.3,1.2,0c0.7,0.6,1.4,0.9,2.3,0.8
	c0.4-0.1,0.8-0.2,0.8-0.7c0.1-0.5-0.2-0.7-0.6-0.8c-0.7-0.3-1.5-0.5-2.2-0.7c-1.2-0.5-1.7-1.3-1.4-2.5c0.1-0.5,0.4-1,0.8-1.3
	c1.2-1,3.4-0.8,4.5,0.3c0.4,0.4,0.5,0.9,0.2,1.2c-0.3,0.3-0.8,0.3-1.2-0.1c-0.5-0.4-1-0.6-1.7-0.6c-0.4,0-0.8,0.1-0.9,0.6
	c-0.1,0.5,0.2,0.8,0.6,0.9c0.4,0.1,0.7,0.2,1.1,0.4c0.3,0.1,0.6,0.2,0.8,0.3c1.1,0.4,1.6,1.1,1.6,2.2c0,0.9-0.6,1.7-1.6,2.1
	C89.7,16.1,89.2,16.1,89,16.2z"
      />
      <path
        d="M93.9,10c-1.1-0.1-1.4-0.3-1.4-0.9c0-0.6,0.3-0.8,1.5-0.9c0.1-0.7-0.3-1.8,1-1.7c1.1,0,0.7,1.1,0.9,1.8c0.3,0,0.7,0,1,0
	c0.6,0,0.8,0.4,0.8,0.9c0,0.5-0.3,0.8-0.8,0.8c-0.3,0-0.6,0-1,0c0,0.2,0,0.5,0,0.7c0,1.1,0,2.1,0,3.2c0,0.6,0.2,0.7,0.8,0.6
	c0.4-0.1,0.8-0.1,1,0.4c0.1,0.5-0.1,0.8-0.7,1.1c-1.5,0.6-2.9-0.3-2.9-1.9c0-1.3,0-2.6,0-3.9C93.9,10.1,93.9,10,93.9,10z"
      />
      <path
        d="M43.7,10c-1.1-0.1-1.4-0.3-1.4-0.8c0-0.6,0.3-0.8,1.5-0.9c0.1-0.7-0.3-1.8,0.9-1.8c1.2,0,0.8,1.1,1,1.8
	c0.7,0.2,1.8-0.3,1.8,0.9c0,1.2-1.1,0.7-1.8,0.9c0,1.3,0,2.6,0,3.9c0,0.5,0.4,0.5,0.8,0.4c0.4-0.1,0.8-0.1,1,0.4
	c0.1,0.5-0.1,0.8-0.6,1c-1.5,0.7-3-0.2-3-1.8c0-1.3,0-2.6,0-3.9C43.8,10.1,43.8,10.1,43.7,10z"
      />
      <path
        d="M59.9,9c0.6-0.7,1.3-1,2.1-1.1c0.5,0,1,0.1,1.2,0.6c0.3,0.7-0.2,1.3-1,1.2c-1.6-0.2-2.3,0.3-2.3,2c0,1,0,2.1,0,3.1
	c0,0.8-0.3,1.2-0.9,1.2c-0.6,0-0.9-0.4-0.9-1.2c0-1.9,0-3.7,0-5.6c0-0.7,0.2-1,0.8-1.1C59.4,8.1,59.7,8.4,59.9,9z"
      />
      <path
        d="M84.3,12c0,1,0,1.9,0,2.9c0,0.7-0.3,1.1-0.9,1.1c-0.6,0-0.9-0.4-0.9-1.1c0-1.9,0-3.8,0-5.7c0-0.7,0.4-1.1,0.9-1.1
	c0.6,0,0.9,0.4,0.9,1.1C84.4,10.1,84.3,11.1,84.3,12C84.3,12,84.3,12,84.3,12z"
      />
      <path d="M84.4,6.3c0,0.7-0.2,0.9-1,0.9c-0.7,0-0.9-0.2-0.9-0.9c0-0.7,0.2-0.9,0.9-0.9C84.2,5.4,84.4,5.6,84.4,6.3z" />
      <circle cx="13.3" cy="7.8" r="1.8" />
      <path
        d="M12.3,2.5c-2,0-3.9,0.9-5.1,2.6C6,7,6.1,9.4,6.9,11.5c0.1-0.3,0.1-0.5,0.2-0.8c-0.9,0.5-1.8,1.1-2.6,1.6
				c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.8,0.1,1.6,0.1,2.4C4.6,15,4.8,14.8,5,14.6c-0.8,0.1-1.7,0.1-2.5,0.2c-0.3,0-0.6,0.2-0.6,0.6
				c0,1,0.1,2,0.1,3c0.2-0.2,0.4-0.4,0.6-0.6c-0.7,0-1.3,0.1-2,0.1c-0.3,0-0.6,0.3-0.6,0.6c0,0.9,0,1.9,0.1,2.9
				c0.2,1.4,1.8,1.3,2.9,1.2c0.6-0.1,1-0.6,1.4-1c0.9-0.8,1.7-1.6,2.6-2.4c1-0.9,2-1.8,3-2.7c0.2-0.2,1.6-1.2,1.6-1.4c0,0,0,0,0,0
				c0.1-0.1,0,0-0.4,0.2c0,0,0.5,0,0.5,0c0.3,0,0.7,0,1,0c0.8-0.1,1.5-0.3,2.2-0.7c1.4-0.8,2.7-2.1,3.3-3.6
				C19.8,6.8,16.4,2.6,12.3,2.5c-0.8,0-0.8,1.2,0,1.2c3.4,0.1,6.3,3.8,4.7,7.1c-0.7,1.4-2,2.5-3.5,3C13,14,12.4,14,11.8,14
				c-0.4,0-0.7,0-1,0.2c-0.4,0.3-0.8,0.8-1.2,1.1C8.8,16,8,16.8,7.2,17.5C5.8,18.8,4.3,20,3,21.3c0,0-0.2,0.1-0.2,0.2
				c0,0.1,0.5,0,0.1-0.1c-0.4-0.1-1,0.2-1.4,0c-0.5-0.2-0.3-1.2-0.3-1.6c0-0.5,0-0.9-0.1-1.4C1,18.7,0.8,18.9,0.6,19
				c0.7,0,1.3-0.1,2-0.1c0.3,0,0.6-0.3,0.6-0.6c0-1-0.1-2-0.1-3c-0.2,0.2-0.4,0.4-0.6,0.6c0.8-0.1,1.7-0.1,2.5-0.2
				c0.3,0,0.6-0.2,0.6-0.6c0-0.8-0.1-1.6-0.1-2.4c-0.1,0.2-0.2,0.3-0.3,0.5c0.9-0.5,1.8-1.1,2.6-1.6C8,11.5,8.1,11.2,8,10.9
				c-1.5-3.5,0.5-7.1,4.4-7.2C13.1,3.7,13.1,2.5,12.3,2.5z"
      />
      <path
        d="M4.4,19.9c1.8-1.7,3.5-3.5,5.3-5.2c0.3-0.2,0.5-0.5,0.8-0.7c0.5-0.5-0.3-1.4-0.8-0.8c-1.8,1.7-3.5,3.5-5.3,5.2
				c-0.3,0.2-0.5,0.5-0.8,0.7C3,19.6,3.8,20.4,4.4,19.9c1.8-1.7,3.5-3.5,5.3-5.2c0.3-0.2,0.5-0.5,0.8-0.7c0.5-0.5-0.3-1.4-0.8-0.8
				c-1.8,1.7-3.5,3.5-5.3,5.2c-0.3,0.2-0.5,0.5-0.8,0.7C3,19.6,3.8,20.4,4.4,19.9z"
      />
      <path
        d="M13.3,9.1c-0.1,0-0.1,0-0.2,0c-0.1,0,0,0,0,0C13,9,12.9,9,12.9,9c0,0-0.1,0-0.1,0c0,0,0.1,0.1,0,0
				c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.1,0,0.1,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1-0.1c0,0-0.1-0.1,0,0c0-0.1-0.1-0.1-0.1-0.2
				c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0,0c0,0,0,0,0-0.1c0-0.1,0-0.2-0.1-0.2c0,0,0-0.1,0,0c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3
				c0-0.1,0,0.1,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0,0c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0.1-0.1c0-0.1-0.1,0.1,0,0
				c0.1-0.1,0.1-0.1,0.2-0.2c-0.1,0.1,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0.2-0.1,0,0c0.1,0,0.2-0.1,0.2-0.1
				c0,0,0.1,0,0.1,0c0.1,0-0.1,0,0.1,0c0.1,0,0.2,0,0.2,0c0,0,0.2,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0.1c0.1,0-0.1-0.1,0,0
				c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0,0c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0,0.1c-0.1-0.1,0,0.1,0,0.1
				c0.1,0.1,0.1,0.2,0.1,0.3c0,0,0-0.1,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.2,0,0.1c0-0.1,0,0.1,0,0.1c0,0,0,0.1,0,0.1
				c0,0,0,0.1,0,0.1c0,0.1,0-0.1,0,0c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1
				c0-0.1,0-0.1,0,0c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1,0.1-0.1,0,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c-0.1,0.1,0.1,0,0,0
				C13.6,9,13.5,9,13.4,9c0,0-0.1,0,0,0C13.4,9.1,13.3,9.1,13.3,9.1c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6
				c1,0,1.9-0.6,2.3-1.6c0.3-0.9,0-2-0.7-2.6C14,5.3,12.9,5.2,12,5.7c-0.9,0.5-1.3,1.6-1.1,2.6c0.2,1.1,1.3,1.9,2.4,1.9
				c0.3,0,0.6-0.3,0.6-0.6C13.8,9.3,13.6,9.1,13.3,9.1z"
      />
      <path d="M25.4,0.2c0,8,0,16,0,24c0,0.3,0.4,0.3,0.4,0c0-8,0-16,0-24C25.8-0.1,25.4-0.1,25.4,0.2L25.4,0.2z" />
    </SvgIcon>
  );
}
