import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { BooleanInput, required } from "react-admin";
import { BoundedTextField } from "./custom-fields";

export default function LeadForm() {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Tenant Information
      </Typography>
      <Grid container columnSpacing={3} rowSpacing={2}>
        <Grid item xs={12} sm={6}>
          <BoundedTextField
            id="firstName"
            name="firstName"
            label="First name"
            source="firstName"
            autoComplete="given-name"
            isRequired={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BoundedTextField
            id="lastName"
            name="lastName"
            label="Last name"
            source="lastName"
            autoComplete="family-name"
            isRequired={true}
          />
        </Grid>
        <Grid item xs={12}>
          <BoundedTextField
            id="email"
            name="email"
            label="Email"
            source="email"
            autoComplete="email"
            isRequired={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BoundedTextField
            id="phoneNumber"
            name="phoneNumber"
            label="Phone number"
            source="phoneNumber"
            isRequired={true}
            type="tel"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BooleanInput label="SMS" source="phone.sms" />
        </Grid>
        <Grid item xs={12}>
          <BoundedTextField
            id="address1"
            name="address1"
            label="Address line 1"
            source="address1"
            autoComplete="address address-line1"
            isRequired={true}
          />
        </Grid>
        <Grid item xs={12}>
          <BoundedTextField
            id="address2"
            name="address2"
            label="Address line 2"
            source="address2"
            autoComplete="address address-line2"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BoundedTextField
            id="city"
            name="city"
            label="City"
            source="city"
            autoComplete="address address-level2"
            isRequired={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BoundedTextField
            id="state"
            name="state"
            label="State/Province/Region"
            source="state"
            isRequired={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BoundedTextField
            id="zip"
            name="zip"
            label="Zip / Postal code"
            source="zip"
            autoComplete="address postal-code"
            isRequired={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BoundedTextField
            id="country"
            name="country"
            label="Country"
            source="country"
            autoComplete="address country"
            isRequired={true}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox color="secondary" name="saveAddress" value="yes" />
            }
            label="Use this address for payment details"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
