import React, { useState } from "react";
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  BooleanInput,
} from "react-admin";

import { AmplifyFilter } from "../components/AmplifyFilter";
import useStyles from "../layout/data-grid-useStyles";
import { SimpleDateField } from "./common-fields";

const defaultQuery = "listTenants";

const TenantFilter = (props: any) => (
  <AmplifyFilter {...props} defaultQuery={defaultQuery}>
    <BooleanInput
      source="tenantsByVacateDate.vacateDate.eq"
      label="Previous Tenants"
      alwaysOn
    />
  </AmplifyFilter>
);

export const TenantList = (props: any) => {
  const [query, setQuery] = useState(defaultQuery);
  const classes = useStyles();
  return (
    <List {...props} filters={<TenantFilter setQuery={setQuery} />}>
      <Datagrid>
        {/* <TextField
          source="id"
          sortBy={query}
          sortable={query === "tenantsByRepresentative"}
        /> */}
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="unitId" />

        <SimpleDateField source="vacateDate" sortable={false} />
        <TextField source="email" sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const TenantShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="email" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

const validateName = [required()];
const validateAccountRepresentative = [required()];

export const TenantEdit = (props: any) => (
  <Edit
    {...props}
    transform={(data) => {
      const { ordersByDate, ordersByStatusDate, ...rest } = data;
      return rest;
    }}
  >
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextInput source="phoneNumber" />
      <TextInput
        source="accountRepresentativeID"
        label="Account representative id"
        validate={validateAccountRepresentative}
      />
    </SimpleForm>
  </Edit>
);

export const TenantCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextInput source="phoneNumber" />
      <TextInput
        source="accountRepresentativeID"
        label="Account representative id"
        validate={validateAccountRepresentative}
      />
      <ReferenceInput
        source="unit"
        reference="units"
        label="Unit"
        filterToQuery={(searchText: any) => ({
          unit: { name: searchText },
        })}
        alwaysOn
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
