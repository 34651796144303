import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { BooleanInput, required } from "react-admin";
import { BoundedTextField } from "./custom-fields";

export default function SpaceForm() {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Space
      </Typography>
      <Grid container columnSpacing={3} rowSpacing={1}></Grid>
    </React.Fragment>
  );
}
