import { useEffect, useState } from "react";
import { MenuItemLink, ResourceDefinitionContext, useDataProvider, useResourceDefinitions } from "react-admin";

import * as Icons from "../components/MenuIcons";

const Menu = (props: any) => {
  const resourceDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourceDefinitions).map(name => resourceDefinitions[name]);

  return (
    <div>
      <MenuItemLink
        to="/"
        primaryText="Dashboard"
        leftIcon={<Icons.Dashboard />}
        {...props}
      />
      {resources.map((resource) => {
        const primaryText = (((resource.options && resource.options.label) ||
          resource.name) as string)
          .split("")
          .map((v, i) => (i === 0 ? v.toUpperCase() : v))
          .join("");
        return (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={primaryText}
            leftIcon={
              resource.icon ? <resource.icon /> : <Icons.DefaultViewList />
            }
            {...props}
          />
        );
      })}
    </div>
  );
};

export default Menu;
