import * as React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import PaymentForm from "./PaymentForm";

// import Review from "./Review";
import { Form, TextInput } from "react-admin";
import LeadForm from "./Lead";
import ServicesForm from "./Services";
import LeaseForm from "./Lease";
import SpaceForm from "./Space";

const validateForm = (values: any) => {
  const errors: any = {};
  if (!values.firstName) {
    errors.firstName = "The firstName is required";
  }
  if (!values.age) {
    // You can return translation keys
    errors.age = "ra.validation.required";
  } else if (values.age < 18) {
    // Or an object if the translation messages need parameters
    errors.age = {
      message: "ra.validation.minValue",
      args: { min: 18 },
    };
  }
  return errors;
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
        Storagist
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const steps = ["Lead", "Space", "Lease", "Services", "Payment"];

function getStepContent(step: number) {
  switch (step) {
    case 0:
      return <LeadForm />;
    case 1:
      return <SpaceForm />;
    case 2:
      return <LeaseForm />;
    case 3:
      return <ServicesForm />;
    case 4:
      return <PaymentForm />;
    default:
      throw new Error("Unknown step");
  }
}

export default function Checkout() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return <Form
      validate={validateForm}>
            <Box
              sx={{
                minWidth: 800,
                margin: "0 auto",
              }}
            >
              <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 2 }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
              <Paper
                variant="outlined"
                sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
              >
                <Typography component="h1" variant="h4" align="center">
                  Move In
                </Typography>

                <React.Fragment>
                  {activeStep === steps.length ? (
                    <React.Fragment>
                      <Typography variant="h5" gutterBottom>
                        Thank you for your order.
                      </Typography>
                      <Typography variant="subtitle1">
                        Your order number is #2001539. We have emailed your
                        order confirmation, and will send you an update when
                        your order has shipped.
                      </Typography>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {getStepContent(activeStep)}
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        {activeStep !== 0 && (
                          <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                            Back
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 3, ml: 1 }}
                        >
                          {activeStep === steps.length - 1
                            ? "Place order"
                            : "Next"}
                        </Button>
                      </Box>
                    </React.Fragment>
                  )}
                </React.Fragment>
              </Paper>
              <Copyright />
            </Container>

    </Form>
}
