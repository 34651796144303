import React from "react";
import {
  ArrayField,
  ChipField,
  Create,
  Datagrid,
  DateField,
  EditButton,
  List,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
} from "react-admin";

export const LocationList = (props: any) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="name" sortable={false} />
        <TextField source="address" sortable={false} />
        <TextField source="phoneNumber" sortable={false} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const LocationShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
    <TextField source="name" />
      <TextField source="address" />
      <TextField source="phoneNumber" />
    </SimpleShowLayout>
  </Show>
);

export const LocationCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="accountId" />
      <TextInput source="id" />
      <TextInput source="name" />
      <TextInput source="address" />
      <TextInput source="phoneNumber" />
    </SimpleForm>
  </Create>
);
