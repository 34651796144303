import { useRecordContext } from "react-admin";
import { Typography, Link } from "@mui/material";

export function PriceField(props: any) {
  const { source } = props;
  const record = useRecordContext(props);
  const price = `$${record[source]}`;
  return <Typography variant="body2">{price}</Typography>;
}

export function SimpleDateField(props: any) {
  const { source } = props;
  const record = useRecordContext();
  const d = new Date(record[source]);
  const currentMonth = d.getMonth() + 1;
  const currentDay = d.getDate();
  const customDate = [
    currentMonth < 10 ? "0" + currentMonth : currentMonth,
    currentDay < 10 ? "0" + currentDay : currentDay,
    d.getFullYear(),
  ].join(" / ");
  return (
    <Typography variant="body2">
      {record[source] ? customDate : "- - -"}
    </Typography>
  );
}

export const FullNameField = () => {
  const record = useRecordContext();
  return (
    <Link
      href={`tenants/${record.id}/show`}
      underline="hover"
    >{`${record.firstName} ${record.lastName}`}</Link>
  );
};
