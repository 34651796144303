import { useRef, memo, useState, useCallback, useEffect } from "react";
import styles from "./map.module.css";
import { Unit } from "./unit";
import { Card, CardContent, Typography } from "@mui/material";

const X_INCREMENT = 20;
const Y_INCREMENT = -40;

const SVGMap = memo(
  (props: { units: Unit[]; scale: number; onHover?: any }) => {
    let maxWidth = 0;
    let maxHeight = 0;

    const svgEl = useRef<SVGSVGElement>(null);
    const handleHover = (
      unit: Unit | null,
      el: SVGPolylineElement | SVGRectElement
    ) => {
      if (props.onHover) {
        if (unit === null) {
          props.onHover(null);
        } else {
          const { x, y, width, height } = el.getBBox();
          const clientBounds = svgEl.current!.getBoundingClientRect();
          props.onHover(unit, {
            x: x + width + X_INCREMENT,
            y: y - height + Y_INCREMENT,
          });
        }
      }
    };
    const units = props.units.map((unit) => {
      if (Array.isArray(unit.shape)) {
        unit.shape.forEach(({ x, y }) => {
          if (x > maxWidth) maxWidth = x;
          if (y > maxHeight) maxHeight = y;
        });
      } else {
        if (unit.shape.x + unit.shape.width > maxWidth)
          maxWidth = unit.shape.x + unit.shape.width;
        if (unit.shape.y + unit.shape.height > maxHeight)
          maxHeight = unit.shape.y + unit.shape.height;
      }
      return (
        <GroupedUnit
          key={`${unit.alias || unit.id}`}
          unit={unit}
          handleHover={handleHover}
        />
      );
      // const { id, statusCode, shape } = unit;
      // if (Array.isArray(shape)) {
      //   return (
      //     <polyline
      //       key={id}
      //       className={styles[statusCode]}
      //       points={shape
      //         .map(({ x, y }) => {
      //           if (x > maxWidth) maxWidth = x;
      //           if (y > maxHeight) maxHeight = y;
      //           return [x, y].join(",");
      //         })
      //         .join(" ")}
      //       onMouseOver={(e) => {
      //         handleHover(unit, e.currentTarget);
      //       }}
      //       onMouseOut={(e) => {
      //         handleHover(null, e.currentTarget);
      //       }}
      //     />
      //   );
      // } else {
      //   if (shape.x + shape.width > maxWidth) maxWidth = shape.x + shape.width;
      //   if (shape.y + shape.height > maxHeight)
      //     maxHeight = shape.y + shape.height;

      //   return (
      //     <rect
      //       key={id}
      //       className={styles[statusCode]}
      //       {...shape}
      //       onMouseOver={(e) => {
      //         handleHover(unit, e.currentTarget);
      //       }}
      //       onMouseOut={(e) => {
      //         handleHover(null, e.currentTarget);
      //       }}
      //     />
      //   );
      // }
    });
    return (
      <svg
        ref={svgEl}
        className={styles.MAP}
        width={maxWidth}
        height={maxHeight}
        viewBox={[0, 0, maxWidth, maxHeight].join(" ")}
      >
        {units}
      </svg>
    );
  }
);

const Map = (props: { units: Unit[]; scale: number }) => {
  const [unitInfo, setUnitInfo] = useState<Unit | null>(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const displayInfo = unitInfo ? (
    <Card sx={{ position: "absolute", top: position.y, left: position.x }}>
      <CardContent sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          variant="body2"
          sx={{ marginRight: 1, color: "GrayText", fontWeight: "bold" }}
        >
          Unit:
        </Typography>
        <Typography>{unitInfo.alias || unitInfo.number}</Typography>
        <Typography
          variant="body2"
          sx={{ marginRight: 1, color: "GrayText", fontWeight: "bold" }}
        >
          Status:
        </Typography>
        <Typography>
          {unitInfo.statusCode
            .split("_")
            .map((fr) =>
              fr
                .split("")
                .map((c, i) => (i === 0 ? c.toUpperCase() : c.toLowerCase()))
                .join("")
            )
            .join(" ")}
        </Typography>
      </CardContent>
    </Card>
  ) : null;

  const handleHover = useCallback(
    (unit: Unit | null, position?: { x: number; y: number }) => {
      setUnitInfo(unit);
      if (position) {
        setPosition(position);
      }
    },
    []
  );

  return (
    <div className={styles.mapContainer}>
      <SVGMap {...props} onHover={handleHover} />
      {displayInfo}
    </div>
  );
};

export default Map;

function GroupedUnit({ unit, handleHover }: { unit: Unit; handleHover: any }) {
  const { id, alias, statusCode, shape } = unit;
  const shapeRef = useRef<any>(null);
  const textRef = useRef<SVGTextElement>(null);
  const [positioned, setPositioned] = useState([0, 0]);

  useEffect(() => {
    const { x, y, width, height } = shapeRef.current.getBBox();
    const { width: textWidth, height: textHeight } = textRef.current!.getBBox();
    setPositioned([
      x + width / 2 - textWidth / 2,
      y + height / 2 + textHeight / 3,
    ]);
  }, []);

  const shapeUnit = Array.isArray(shape) ? (
    <polyline
      ref={shapeRef}
      key={id}
      className={styles[statusCode]}
      points={shape
        .map(({ x, y }) => {
          return [x, y].join(",");
        })
        .join(" ")}
    />
  ) : (
    <rect ref={shapeRef} className={styles[statusCode]} {...shape} />
  );

  return (
    <g
      onMouseOver={(e) => {
        handleHover(unit, e.currentTarget);
      }}
      onMouseOut={(e) => {
        handleHover(null, e.currentTarget);
      }}
    >
      {shapeUnit}
      <text
        style={{ margin: 0, padding: 0 }}
        ref={textRef}
        fill={
          statusCode === "UNOCCUPIED" || statusCode === "OCCUPIED_LOCKED_OUT"
            ? "white"
            : undefined
        }
        fontSize={10}
        x={positioned[0]}
        y={positioned[1]}
      >
        {id}
      </text>
    </g>
  );
}
