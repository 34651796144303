import React, { useState } from "react";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  List,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  required,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";

import { AmplifyFilter } from "../components/AmplifyFilter";
import useStyles from "../layout/data-grid-useStyles";

const defaultQuery = "listLeads";

const LeadFilter = (props: any) => (
  <AmplifyFilter {...props} defaultQuery={defaultQuery}>
    <TextInput
      source="LeadsByRepresentative.accountRepresentativeID"
      label="Account representative id"
      alwaysOn
      resettable
    />
  </AmplifyFilter>
);

export const LeadList = (props: any) => {
  const [query, setQuery] = useState(defaultQuery);
  const classes = useStyles();
  return (
    <List {...props}
    //  filters={<LeadFilter setQuery={setQuery} />}
     >
      <Datagrid >
        {/* <TextField
          source="id"
          sortBy={query}
          sortable={query === "LeadsByRepresentative"}
        /> */}
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="phoneNumber" sortable={false} />
        <TextField source="email" sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const LeadShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="phoneNumber" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

const validateName = [required()];
const validateAccountRepresentative = [required()];

export const LeadEdit = (props: any) => (
  <Edit
    {...props}
    transform={(data) => {
      const { ordersByDate, ordersByStatusDate, ...rest } = data;
      return rest;
    }}
  >
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="phoneNumber" />
    </SimpleForm>
  </Edit>
);

export const LeadCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="phoneNumber" />
      <TextInput
        source="accountRepresentativeID"
        label="Account representative id"
        validate={validateAccountRepresentative}
      />
    </SimpleForm>
  </Create>
);
