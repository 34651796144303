import * as React from "react";
import { Box, Button, Modal, Card, CardContent } from "@mui/material";
import Map from "./map";
import { FAKE_UNITS } from "./unit";

export default function MapModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Card sx={{ maxWidth: 200 }}>
        <CardContent>
          <Button onClick={handleOpen}>Open Map</Button>
        </CardContent>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "5px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Map units={FAKE_UNITS} scale={1} />
        </Box>
      </Modal>
    </div>
  );
}
