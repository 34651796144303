import { API, graphqlOperation } from "@aws-amplify/api";
import { Amplify } from "@aws-amplify/core";
import { Storage } from "@aws-amplify/storage";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Admin, CustomRoutes, DataProvider, Resource } from "react-admin";
import { CognitoUserList, CognitoUserShow } from "./components/CognitoUser";
import { CognitoGroupList } from "./components/CognitoGroup";
import { buildAuthProvider } from "./providers";
import awsExports from "./aws-exports";
import {
  AccountRepresentativeCreate,
  AccountRepresentativeEdit,
  AccountRepresentativeList,
  AccountRepresentativeShow,
} from "./components/AccountRepresentative";
import { TenantCreate, TenantEdit, TenantList } from "./components/Tenant";
import { LeadCreate, LeadEdit, LeadList, LeadShow } from "./components/Lead";
import { UnitCreate, UnitEdit, UnitList, UnitShow } from "./components/Unit";
// import { Dashboard } from "./components/Dashboard";

import Dashboard from "./components/dashboard/dashboard";
import {
  EmployeeCreate,
  EmployeeEdit,
  EmployeeList,
  EmployeeShow,
} from "./components/Employee";
import { LoginPage } from "./components/LoginPage";
import {
  OrderCreate,
  OrderEdit,
  OrderList,
  OrderShow,
} from "./components/Order";
import {
  ProductCreate,
  ProductEdit,
  ProductList,
  ProductShow,
} from "./components/Product";
import {
  PaymentCreate,
  PaymentEdit,
  PaymentList,
  PaymentShow,
} from "./components/Payment";
import {
  LocationCreate,
  LocationList,
  LocationShow,
} from "./components/Location";
import * as Icons from "./components/MenuIcons";
import CustomLayout from "./layout/layout";
import { MoveInList } from "./components/move-in/move-in";
import { MoveInCreate } from "./components/move-in/move-in-create";
import { getDataProvider } from "./providers/data";
import Checkout from "./components/checkout/Checkout";
import { TenantShow } from "./components/tenant/tenant-show";
import englishMessages from './i18n/en';
import { Route } from "react-router-dom";
import Segments from "./segments/Segments";
import Configuration from "./configuration/configuration";

const i18nProvider = polyglotI18nProvider(locale => {
  if (locale === 'fr') {
      return import('./i18n/fr').then(messages => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, 'en');

Amplify.configure(awsExports);

const authProvider = buildAuthProvider({
  authGroups: ["superadmin", "admin"],
});

// Get the demo user avatar
// authProvider.getIdentity = async () => {
//   try {
//     const userData = await API.graphql(
//       graphqlOperation(queries.getUser, { id: "demo" })
//     );

//     const url = await Storage.get(userData.data.getUser.picture.key);

//     return {
//       id: "demo",
//       fullName: "Demo",
//       avatar: url,
//     };
//   } catch (e) {
//     console.log(e);
//   }
// };

let dataProvider = getDataProvider();

function App() {
  return (
    <Admin
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      loginPage={LoginPage}
      dashboard={Dashboard}
      layout={CustomLayout as any}
      // theme={AdminTheme}
    >
      {(permissions) => [
        // <Resource
        //   name="orders"
        //   list={OrderList}
        //   show={OrderShow}
        //   edit={OrderEdit}
        //   create={OrderCreate}
        //   icon={Icons.Orders}
        // />,
        <Resource
          name="leads"
          list={LeadList}
          show={LeadShow}
          edit={LeadEdit}
          create={LeadCreate}
          icon={Icons.Leads}
        />,
        <Resource
          name="tenants"
          list={TenantList}
          show={TenantShow}
          edit={TenantEdit}
          // create={MoveInCreate}
          create={Checkout}
          icon={Icons.Tenants}
        />,
        <Resource
          name="units"
          list={UnitList}
          show={UnitShow}
          edit={UnitEdit}
          create={UnitCreate}
          icon={Icons.Units}
        />,
        <Resource
          name="employees"
          list={EmployeeList}
          show={EmployeeShow}
          edit={EmployeeEdit}
          create={EmployeeCreate}
          icon={Icons.Employees}
        />,
        <Resource
          name="locations"
          list={LocationList}
          show={LocationShow}
          create={LocationCreate}
          icon={Icons.Locations}
        />,
        // <Resource
        //   name="accountRepresentatives"
        //   options={{ label: "Account Reps" }}
        //   list={AccountRepresentativeList}
        //   show={AccountRepresentativeShow}
        //   edit={AccountRepresentativeEdit}
        //   create={AccountRepresentativeCreate}
        //   icon={Icons.AccountReps}
        // />,
        <Resource
          name="payments"
          list={PaymentList}
          show={PaymentShow}
          edit={PaymentEdit}
          create={PaymentCreate}
          icon={Icons.Payments}
        />,
        // <Resource
        //   name="products"
        //   list={ProductList}
        //   show={ProductShow}
        //   edit={ProductEdit}
        //   create={ProductCreate}
        //   icon={Icons.Products}
        // />,
        permissions.includes("superadmin") ? (
          <Resource
            name="cognitoUsers"
            options={{ label: "Cognito Users" }}
            list={CognitoUserList}
            show={CognitoUserShow}
          />
        ) : null,
        permissions.includes("superadmin") ? (
          <Resource
            name="cognitoGroups"
            options={{ label: "Cognito Groups" }}
            list={CognitoGroupList}
          />
        ) : null,
      ]}
      {/* <CustomRoutes>
        <Route path="/configuration" element={ <Configuration />} />,
        <Route path="/segments" element={ <Segments />} />,
      </CustomRoutes> */}
    </Admin>
  );
}

export default App;
