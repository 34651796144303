/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      tenantID
      accountRepresentativeID
      productID
      status
      amount
      date
      createdAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      tenantID
      accountRepresentativeID
      productID
      status
      amount
      date
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      tenantID
      accountRepresentativeID
      productID
      status
      amount
      date
      createdAt
      updatedAt
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      accountId
      locationId
      customerId
      paymentDate
      paymentAmount
      unitId
      amountDue
      paymentType
      checkNumber
      createdAt
      updatedAt
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      accountId
      locationId
      customerId
      paymentDate
      paymentAmount
      unitId
      amountDue
      paymentType
      checkNumber
      createdAt
      updatedAt
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      accountId
      locationId
      customerId
      paymentDate
      paymentAmount
      unitId
      amountDue
      paymentType
      checkNumber
      createdAt
      updatedAt
    }
  }
`;
export const createTenant = /* GraphQL */ `
  mutation CreateTenant(
    $input: CreateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    createTenant(input: $input, condition: $condition) {
      id
      accountId
      locationId
      address {
        address1
        address2
        city
        state
        zipCode
      }
      companyName
      phones {
        number
        extension
        type
      }
      firstName
      lastName
      deposit
      unitId
      notes
      customerSince
      driverLicenseNumber
      vacateDate
      gateAccessCode
      moneyOwed
      email
      ssn
      billing {
        creditCard {
          card
          cvv
          exp
          alias
        }
      }
      paymentsByDate {
        items {
          id
          accountId
          locationId
          customerId
          paymentDate
          paymentAmount
          unitId
          amountDue
          paymentType
          checkNumber
          createdAt
          updatedAt
        }
        nextToken
      }
      ordersByStatusDate {
        items {
          id
          tenantID
          accountRepresentativeID
          productID
          status
          amount
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      id
      accountId
      locationId
      address {
        address1
        address2
        city
        state
        zipCode
      }
      companyName
      phones {
        number
        extension
        type
      }
      firstName
      lastName
      deposit
      unitId
      notes
      customerSince
      driverLicenseNumber
      vacateDate
      gateAccessCode
      moneyOwed
      email
      ssn
      billing {
        creditCard {
          card
          cvv
          exp
          alias
        }
      }
      paymentsByDate {
        items {
          id
          accountId
          locationId
          customerId
          paymentDate
          paymentAmount
          unitId
          amountDue
          paymentType
          checkNumber
          createdAt
          updatedAt
        }
        nextToken
      }
      ordersByStatusDate {
        items {
          id
          tenantID
          accountRepresentativeID
          productID
          status
          amount
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTenant = /* GraphQL */ `
  mutation DeleteTenant(
    $input: DeleteTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    deleteTenant(input: $input, condition: $condition) {
      id
      accountId
      locationId
      address {
        address1
        address2
        city
        state
        zipCode
      }
      companyName
      phones {
        number
        extension
        type
      }
      firstName
      lastName
      deposit
      unitId
      notes
      customerSince
      driverLicenseNumber
      vacateDate
      gateAccessCode
      moneyOwed
      email
      ssn
      billing {
        creditCard {
          card
          cvv
          exp
          alias
        }
      }
      paymentsByDate {
        items {
          id
          accountId
          locationId
          customerId
          paymentDate
          paymentAmount
          unitId
          amountDue
          paymentType
          checkNumber
          createdAt
          updatedAt
        }
        nextToken
      }
      ordersByStatusDate {
        items {
          id
          tenantID
          accountRepresentativeID
          productID
          status
          amount
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLead = /* GraphQL */ `
  mutation CreateLead(
    $input: CreateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    createLead(input: $input, condition: $condition) {
      id
      accountId
      locationId
      firstName
      lastName
      phoneNumber
      desiredUnitType
      desiredMovinDate
      email
      accountRepresentativeID
      source
      createdAt
      updatedAt
    }
  }
`;
export const updateLead = /* GraphQL */ `
  mutation UpdateLead(
    $input: UpdateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    updateLead(input: $input, condition: $condition) {
      id
      accountId
      locationId
      firstName
      lastName
      phoneNumber
      desiredUnitType
      desiredMovinDate
      email
      accountRepresentativeID
      source
      createdAt
      updatedAt
    }
  }
`;
export const deleteLead = /* GraphQL */ `
  mutation DeleteLead(
    $input: DeleteLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    deleteLead(input: $input, condition: $condition) {
      id
      accountId
      locationId
      firstName
      lastName
      phoneNumber
      desiredUnitType
      desiredMovinDate
      email
      accountRepresentativeID
      source
      createdAt
      updatedAt
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      id
      name
      startDate
      phoneNumber
      facilityID
      jobTitle
      newHire
      createdAt
      updatedAt
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      name
      startDate
      phoneNumber
      facilityID
      jobTitle
      newHire
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      id
      name
      startDate
      phoneNumber
      facilityID
      jobTitle
      newHire
      createdAt
      updatedAt
    }
  }
`;
export const createUnit = /* GraphQL */ `
  mutation CreateUnit(
    $input: CreateUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    createUnit(input: $input, condition: $condition) {
      locationId
      id
      tenantId
      name
      tenant {
        id
        accountId
        locationId
        address {
          address1
          address2
          city
          state
          zipCode
        }
        companyName
        phones {
          number
          extension
          type
        }
        firstName
        lastName
        deposit
        unitId
        notes
        customerSince
        driverLicenseNumber
        vacateDate
        gateAccessCode
        moneyOwed
        email
        ssn
        paymentsByDate {
          nextToken
        }
        ordersByStatusDate {
          nextToken
        }
        createdAt
        updatedAt
      }
      width
      length
      height
      status
      size
      rate
      yearlyRent
      previousOccupiedDate
      vacantDate
      estimatedMoveOutDate
      createdAt
      updatedAt
    }
  }
`;
export const updateUnit = /* GraphQL */ `
  mutation UpdateUnit(
    $input: UpdateUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    updateUnit(input: $input, condition: $condition) {
      locationId
      id
      tenantId
      name
      tenant {
        id
        accountId
        locationId
        address {
          address1
          address2
          city
          state
          zipCode
        }
        companyName
        phones {
          number
          extension
          type
        }
        firstName
        lastName
        deposit
        unitId
        notes
        customerSince
        driverLicenseNumber
        vacateDate
        gateAccessCode
        moneyOwed
        email
        ssn
        paymentsByDate {
          nextToken
        }
        ordersByStatusDate {
          nextToken
        }
        createdAt
        updatedAt
      }
      width
      length
      height
      status
      size
      rate
      yearlyRent
      previousOccupiedDate
      vacantDate
      estimatedMoveOutDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteUnit = /* GraphQL */ `
  mutation DeleteUnit(
    $input: DeleteUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    deleteUnit(input: $input, condition: $condition) {
      locationId
      id
      tenantId
      name
      tenant {
        id
        accountId
        locationId
        address {
          address1
          address2
          city
          state
          zipCode
        }
        companyName
        phones {
          number
          extension
          type
        }
        firstName
        lastName
        deposit
        unitId
        notes
        customerSince
        driverLicenseNumber
        vacateDate
        gateAccessCode
        moneyOwed
        email
        ssn
        paymentsByDate {
          nextToken
        }
        ordersByStatusDate {
          nextToken
        }
        createdAt
        updatedAt
      }
      width
      length
      height
      status
      size
      rate
      yearlyRent
      previousOccupiedDate
      vacantDate
      estimatedMoveOutDate
      createdAt
      updatedAt
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      accountId
      name
      address
      phoneNumber
      employees {
        items {
          id
          name
          startDate
          phoneNumber
          facilityID
          jobTitle
          newHire
          createdAt
          updatedAt
        }
        nextToken
      }
      units {
        items {
          locationId
          id
          tenantId
          name
          width
          length
          height
          status
          size
          rate
          yearlyRent
          previousOccupiedDate
          vacantDate
          estimatedMoveOutDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      accountId
      name
      address
      phoneNumber
      employees {
        items {
          id
          name
          startDate
          phoneNumber
          facilityID
          jobTitle
          newHire
          createdAt
          updatedAt
        }
        nextToken
      }
      units {
        items {
          locationId
          id
          tenantId
          name
          width
          length
          height
          status
          size
          rate
          yearlyRent
          previousOccupiedDate
          vacantDate
          estimatedMoveOutDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      accountId
      name
      address
      phoneNumber
      employees {
        items {
          id
          name
          startDate
          phoneNumber
          facilityID
          jobTitle
          newHire
          createdAt
          updatedAt
        }
        nextToken
      }
      units {
        items {
          locationId
          id
          tenantId
          name
          width
          length
          height
          status
          size
          rate
          yearlyRent
          previousOccupiedDate
          vacantDate
          estimatedMoveOutDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAccountRepresentative = /* GraphQL */ `
  mutation CreateAccountRepresentative(
    $input: CreateAccountRepresentativeInput!
    $condition: ModelAccountRepresentativeConditionInput
  ) {
    createAccountRepresentative(input: $input, condition: $condition) {
      id
      orders {
        items {
          id
          tenantID
          accountRepresentativeID
          productID
          status
          amount
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      orderTotal
      salesPeriod
      createdAt
      updatedAt
    }
  }
`;
export const updateAccountRepresentative = /* GraphQL */ `
  mutation UpdateAccountRepresentative(
    $input: UpdateAccountRepresentativeInput!
    $condition: ModelAccountRepresentativeConditionInput
  ) {
    updateAccountRepresentative(input: $input, condition: $condition) {
      id
      orders {
        items {
          id
          tenantID
          accountRepresentativeID
          productID
          status
          amount
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      orderTotal
      salesPeriod
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccountRepresentative = /* GraphQL */ `
  mutation DeleteAccountRepresentative(
    $input: DeleteAccountRepresentativeInput!
    $condition: ModelAccountRepresentativeConditionInput
  ) {
    deleteAccountRepresentative(input: $input, condition: $condition) {
      id
      orders {
        items {
          id
          tenantID
          accountRepresentativeID
          productID
          status
          amount
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      orderTotal
      salesPeriod
      createdAt
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      price
      orders {
        items {
          id
          tenantID
          accountRepresentativeID
          productID
          status
          amount
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      price
      orders {
        items {
          id
          tenantID
          accountRepresentativeID
          productID
          status
          amount
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      price
      orders {
        items {
          id
          tenantID
          accountRepresentativeID
          productID
          status
          amount
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      password
      picture {
        bucket
        region
        key
      }
      documents {
        bucket
        region
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      password
      picture {
        bucket
        region
        key
      }
      documents {
        bucket
        region
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      password
      picture {
        bucket
        region
        key
      }
      documents {
        bucket
        region
        key
      }
      createdAt
      updatedAt
    }
  }
`;
