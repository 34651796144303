export interface Unit {
  id: string;
  number?: number;
  alias?: string;
  statusCode:
    | "UNOCCUPIED"
    | "UNOCCUPIED_DAMAGED"
    | "OCCUPIED_CURRENT"
    | "OCCUPIED_LOCKED_OUT"
    | "OCCUPIED_IN_GRACE"
    | "OCCUPIED_PAST_DUE"
    | "OCCUPIED_AUCTION"
    | "CUSTOM";
  shape:
    | { x: number; y: number; width: number; height: number }
    | { x: number; y: number }[];
}

const FAKE_UNITS: Unit[] = [];

let x = 0;
let y = 0;

const INCREMENT = 11;

for (let i = 134; i >= 98; i--) {
  const width = INCREMENT * 3;
  FAKE_UNITS.push({
    id: i.toString(),
    number: i,
    statusCode: getStatusCode(i),
    shape: {
      x,
      y,
      width,
      height: INCREMENT * 10,
    },
  });
  x += width;
}

for (let i = 93; i <= 96; i++) {
  FAKE_UNITS.push({
    id: i.toString(),
    number: i,
    statusCode: getStatusCode(i),
    shape: {
      x,
      y,
      width: INCREMENT * 2,
      height: INCREMENT * (i === 96 ? 4 : 2),
    },
  });
  y += INCREMENT * 2;
}

x += INCREMENT * 3;
y = 0;

for (let i = 91; i >= 68; i--) {
  const height =
    INCREMENT * (i === 91 || i === 71 ? 4 : i <= 86 && i > 73 ? 3 : 2);
  FAKE_UNITS.push({
    id: i.toString(),
    number: i,
    statusCode: getStatusCode(i),
    shape: {
      x: i > 86 || i < 74 ? x + INCREMENT * 3 : x,
      y,
      width: INCREMENT * (i > 86 || i < 74 ? 3 : 6),
      height,
    },
  });
  y += height;
}

for (let i = 66; i > 0; i--) {
  let width = INCREMENT * 3,
    height = INCREMENT * 10;
  let isUnit = true;
  if (i === 66 || i === 63) {
    if (i === 66) x -= INCREMENT * 6;
    width = width * 2;
    height = INCREMENT * (i === 66 ? 4 : 6);
    y -= height;
    // dy = -height;
  } else if ((i < 63 && i > 47) || (i < 18 && i > 15)) {
    x -= width;
  } else if (i < 16) {
    height = INCREMENT * 2;
    if (i === 15) y += INCREMENT * 8;
    if (i % 5 === 0) {
      x -= INCREMENT * (i === 10 ? 3 : 6);
    } else {
      y += INCREMENT * 2 * (i < 11 && i > 5 ? 1 : -1);
    }
  } else {
    isUnit = false;
  }

  if (isUnit) {
    FAKE_UNITS.push({
      id: i.toString(),
      number: i,
      statusCode: getStatusCode(i),
      shape: {
        x,
        y,
        width,
        height,
      },
    });
  }
}

y += INCREMENT * 10;
x = 0;

for (let i = 149; i >= 135; i--) {
  const height = INCREMENT * (i === 149 ? 4 : i > 148 || i < 146 ? 3 : 2);
  const width = INCREMENT * (i < 145 ? 6 : 3);
  FAKE_UNITS.push({
    id: i.toString(),
    number: i,
    statusCode: getStatusCode(i),
    shape: {
      x,
      y: y - height,
      width,
      height,
    },
  });
  y -= height;
}

const currentY = y;
x += INCREMENT * 9;
const currentX = x;

const UP_LINE = [313, 306, 300, 294, 288, 282, 276, 270, 264, 258, 252];

UP_LINE.forEach((u) => {
  const base = u === 313 || u === 252 ? 2 : 1;
  const width = INCREMENT * 3;
  for (let i = u; i >= u - base; i--) {
    const height = INCREMENT * 6;
    FAKE_UNITS.push({
      id: i.toString(),
      number: i,
      statusCode: getStatusCode(i),
      shape: {
        x,
        y,
        width,
        height,
      },
    });
    if (i === u || i === u - base) {
      const partialHeight = INCREMENT * 3;
      const partialWidth = i === 313 || i === 250 ? height : partialHeight;
      const maxJ = i === 313 || i === 250 ? 5 : 2;
      for (let j = 1; j <= maxJ; j++) {
        const id = i === u ? i + j : i - j;
        FAKE_UNITS.push({
          id: id.toString(),
          number: id,
          statusCode: getStatusCode(id),
          shape: {
            x: i === 250 ? x - partialHeight : x,
            y: y + height + partialHeight * (j - 1),
            width: partialWidth,
            height: partialHeight,
          },
        });
      }
    }
    x += width;
  }
  x += width;
});

const unit318 = FAKE_UNITS.find((u) => u.number === 318)!.shape as {
  x: number;
  y: number;
  width: number;
  height: number;
};

x = unit318.x;
y = unit318.y + unit318.height;

const bigUnits = [161, 162, 210, 211, 243, 244];
const irregularUnits = [
  163,
  171,
  178,
  179,
  186,
  187,
  194,
  195,
  202,
  203,
  218,
  219,
  226,
  227,
  234,
  235,
];
const bigHeight = INCREMENT * 7;
const maxY = y + bigHeight;
const smallHeight = INCREMENT * 2;
const regularHeight = INCREMENT * 3;
const irregularHeight = INCREMENT * 5;
const constantWidth = INCREMENT * 3;
let direction: "right" | "down" | "up" = "right";
let smallUnit = 320;
let bigUnit = 161;
const INTERVAL = [
  [3, 1, 3],
  [3, 2, 2, 1, 3],
  [3, 1, 2, 1, 3],
  [3, 1, 2, 1, 3],
  [3, 1, 2, 1, 3],
  [3, 1, 2, 1, 3],
  [8],
  [3, 1, 2, 1, 3],
  [3, 1, 2, 1, 3],
  [3, 1, 2, 1, 3],
  [3, 2, 3],
];

INTERVAL.forEach((g, i) => {
  const maxX =
    x + constantWidth * (i === 0 || i === INTERVAL.length - 1 ? 2 : 1);
  if (i !== 0) {
    direction = "down";
    x += constantWidth * 2;
  }
  g.forEach((intervalIndex, j) => {
    let isSmall = j % 2 === 1;
    for (let k = 0; k < intervalIndex; k++) {
      const currentUnit = isSmall ? smallUnit : bigUnit;
      let height = isSmall
        ? smallHeight
        : bigUnits.includes(currentUnit)
        ? bigHeight
        : direction === "right" && currentUnit !== 170
        ? irregularHeight
        : regularHeight;
      if (
        irregularUnits.includes(currentUnit) ||
        (bigUnit >= 243 && !isSmall)
      ) {
        height = bigUnit >= 243 ? bigHeight : irregularHeight;
        y = maxY - height;
        if (currentUnit === 244) {
          x += constantWidth;
          direction = "right";
        }
      }
      FAKE_UNITS.push({
        id: currentUnit.toString(),
        number: currentUnit,
        statusCode: getStatusCode(currentUnit),
        shape: {
          x,
          y: direction === "up" ? y - height : y,
          width: constantWidth,
          height,
        },
      });
      if (direction === "right") {
        if (x + constantWidth > maxX) {
          // y += height;
          direction = "up";
        } else {
          x += constantWidth;
        }
      } else if (direction === "up") {
        y -= height;
      } else {
        if (y + height >= maxY) {
          x += constantWidth;
          direction = "right";
        } else {
          y += height;
        }
      }
      if (currentUnit === 331) {
        smallUnit += 3;
      } else {
        isSmall ? (smallUnit += 1) : (bigUnit += 1);
      }
      if (smallUnit === 340) {
        smallUnit = 342;
      } else if (smallUnit === 343) {
        smallUnit = 341;
      }
    }
  });
});

const unit149 = FAKE_UNITS.find((u) => u.number === 149)!.shape as {
  x: number;
  y: number;
  width: number;
  height: number;
};

x = unit149.x + +unit149.width + INCREMENT * 2;
y = unit149.y + unit149.height;

for (let i = 151; i <= 160; i++) {
  const height =
    i < 155 ? smallHeight : i < 160 ? INCREMENT * 8 : INCREMENT * 4;
  const width = i === 160 ? INCREMENT * 4 : constantWidth;
  FAKE_UNITS.push({
    id: i.toString(),
    number: i,
    statusCode: getStatusCode(i),
    shape: {
      x,
      y: i < 155 ? y - height : y,
      width,
      height,
    },
  });
  if (i < 155) {
    y -= height;
    if (i === 154) x += width;
  } else {
    x += width;
  }
}

function getStatusCode(i: number): Unit["statusCode"] {
  const occupiedPastDue = [
    342,
    309,
    300,
    291,
    278,
    275,
    268,
    267,
    169,
    187,
    217,
    223,
    232,
    152,
    133,
    128,
    96,
    90,
    63,
    57,
    49,
    48,
  ];
  const unoccupied = [
    341,
    334,
    331,
    294,
    257,
    250,
    230,
    181,
    156,
    157,
    148,
    139,
    137,
    131,
    118,
    113,
    75,
    74,
    69,
    68,
    66,
    59,
    53,
    6,
    5,
  ];
  const occupiedLockedOut = [302];
  if (occupiedPastDue.includes(i)) {
    return "OCCUPIED_PAST_DUE";
  } else if (unoccupied.includes(i)) {
    return "UNOCCUPIED";
  } else if (occupiedLockedOut.includes(i)) {
    return "OCCUPIED_LOCKED_OUT";
  } else {
    return "OCCUPIED_CURRENT";
  }
}

export { FAKE_UNITS };
