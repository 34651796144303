import React, { useState } from "react";
import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  List,
  ReferenceField,
  required,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import { AmplifyFilter } from "../components/AmplifyFilter";
import useStyles from "../layout/data-grid-useStyles";

const defaultQuery = "listEmployees";

const EmployeeFilter = (props: any) => (
  <AmplifyFilter {...props} defaultQuery={defaultQuery}>
    <SelectInput
      source="employeesNewHireByStartDate.newHire"
      label="New hire"
      choices={[
        { id: "false", name: "false" },
        { id: "true", name: "true" },
      ]}
      alwaysOn
      resettable
    />
    <DateInput
      source="employeesNewHireByStartDate.startDate.eq"
      label="Start date"
      alwaysOn
    />
    <TextInput source="employeesByName.name" label="Name" alwaysOn resettable />
    <TextInput
      source="employeesByJobTitle.jobTitle"
      label="Job title"
      alwaysOn
      resettable
    />
    <TextInput
      source="employeesByFacility.facilityID"
      label="Facility id"
      alwaysOn
      resettable
    />
  </AmplifyFilter>
);

export const EmployeeList = (props: any) => {
  const [query, setQuery] = useState(defaultQuery);
  const classes = useStyles();
  return (
    <List {...props} filters={<EmployeeFilter setQuery={setQuery} />}>
      <Datagrid>
        <TextField source="name" sortable={false} />
        <DateField
          source="startDate"
          sortBy={query}
          sortable={query === "employeesNewHireByStartDate"}
        />
        <TextField source="phoneNumber" sortable={false} />
        <TextField source="jobTitle" sortable={false} />
        <TextField
          source="newHire"
          sortBy={query}
          sortable={query === "employeesNewHireByStartDate"}
        />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const EmployeeShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="startDate" />
      <TextField source="phoneNumber" />
      <TextField source="jobTitle" />
      <TextField source="newHire" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

const validateName = [required()];
const validateStartDate = [required()];
const validatePhoneNumber = [required()];
const validateFacility = [required()];
const validateJobTitle = [required()];
const validateNewHire = [required()];

export const EmployeeEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="name" validate={validateName} />
      <DateInput source="startDate" validate={validateStartDate} />
      <TextInput source="phoneNumber" validate={validatePhoneNumber} />
      <TextInput
        source="facilityID"
        label="Facility id"
        validate={validateFacility}
      />
      <TextInput source="jobTitle" validate={validateJobTitle} />
      <SelectInput
        source="newHire"
        choices={[
          { id: "false", name: "false" },
          { id: "true", name: "true" },
        ]}
        validate={validateNewHire}
      />
    </SimpleForm>
  </Edit>
);

export const EmployeeCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="name" validate={validateName} />
      <DateInput source="startDate" validate={validateStartDate} />
      <TextInput source="phoneNumber" validate={validatePhoneNumber} />
      <TextInput
        source="facilityID"
        label="Facility id"
        validate={validateFacility}
      />
      <TextInput source="jobTitle" validate={validateJobTitle} />
      <SelectInput
        source="newHire"
        choices={[
          { id: "false", name: "false" },
          { id: "true", name: "true" },
        ]}
        validate={validateNewHire}
      />
    </SimpleForm>
  </Create>
);
