import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function LeaseForm() {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Lease
      </Typography>
      <Grid container columnSpacing={3} rowSpacing={1}></Grid>
    </React.Fragment>
  );
}
