import React, { useState } from "react";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  List,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  required,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  SelectInput,
} from "react-admin";
import { AmplifyFilter } from "../components/AmplifyFilter";
import UnitStatusCard from "./unit-status-card";
import useSytles from "../layout/data-grid-useStyles";
import { PriceField, FullNameField } from "./common-fields";

const defaultQuery = "listUnits";
/**
  VACANT
  VACANT_DAMAGED
  OCCUPIED
  OCCUPIED_PAST_DUE
  OCCUPIED_LOCKED_OUT
  OCCUPIED_IN_GRACE
  OCCUPIED_AUCTION
}
 */
const statusChoices = [
  { id: "VACANT", name: "Vacant" },
  { id: "OCCUPIED", name: "Occupied" },
  { id: "OCCUPIED_LOCKED_OUT", name: "Locked out" },
  { id: "OCCUPIED_PAST_DUE", name: "Past due" },
];

const UnitFilter = (props: any) => (
  <AmplifyFilter {...props} defaultQuery={defaultQuery}>
    <SelectInput
      label="status"
      source="unitsByStatus.status"
      alwaysOn
      choices={statusChoices}
    />
  </AmplifyFilter>
);

export const UnitList = (props: any) => {
  const [query, setQuery] = useState(defaultQuery);

  const classes = useSytles();
  return (
    <List {...props} filters={<UnitFilter setQuery={setQuery} />}>
      <Datagrid>
        <TextField source="id" sortable={false} />
        <TextField source="size" sortable={false} />
        <PriceField source="rate" label="Monthly Rate" sortable={true} />
        <ReferenceField
          label="Tenant"
          source="tenantId"
          reference="tenants"
          link="show"
        >
          <FullNameField />
        </ReferenceField>
        <UnitStatusCard source="status" sortBy={query} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const UnitShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="locationId" />
      <TextField source="name" />
      <TextField source="size" sortable={false} />
      <TextField source="rate" label="Monthly Rate" />
      <TextField source="status" />
    </SimpleShowLayout>
  </Show>
);

const validateName = [required()];
const validateAccountRepresentative = [required()];

export const UnitEdit = (props: any) => (
  <Edit
    {...props}
    transform={(data) => {
      const { ordersByDate, ordersByStatusDate, ...rest } = data;
      return rest;
    }}
  >
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="locationId" disabled />
      <TextInput source="name" validate={validateName} />
      <TextInput source="width" type="number" />
      <TextInput source="length" type="number" />
      <TextInput source="height" type="number" />
      <TextInput
        source="accountRepresentativeID"
        label="Account representative id"
        validate={validateAccountRepresentative}
      />
    </SimpleForm>
  </Edit>
);

export const UnitCreate = (props: any) => {
  let onSubmit = (data: any) => {
    console.log(data);
  };
  return (
    <Create {...props}>
      <SimpleForm onSubmit={onSubmit}>
        <TextInput source="id" />
        <TextInput source="name" validate={validateName} />
        <TextInput source="width" type="number" />
        <TextInput source="length" type="number" />
        <TextInput source="height" type="number" />
        <TextInput source="size" />
        <TextInput source="rate" label="Monthly Rate" placeholder="15" />
        <SelectInput
          source="status"
          choices={[
            { id: "VACANT", name: "Vacant" },
            { id: "OCCUPIED", name: "Occupied" },
          ]}
        />
        <SelectInput source="status" choices={statusChoices} />
      </SimpleForm>
    </Create>
  );
};
