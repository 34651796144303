import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useRecordContext } from "react-admin";

export default function UnitStatusCard(props: any) {
  const { source } = props;
  const record = useRecordContext(props);
  const status = record[source];

  const vacantGradient =
    "radial-gradient(farthest-side at -20px -20px, #eee, #555)";
  const occupiedGradient =
    "radial-gradient(farthest-side at -20px -20px, #eeffee, #33cc77)";
  const occupiedPastDue =
    "radial-gradient(farthest-side at -20px -20px, #eee, #cc3377)";
  const occupiedLockedOut =
    "radial-gradient(farthest-side at -20px -20px, #eee, #3333cc)";
  function getBackground(status: String) {
    if (status === "VACANT") return vacantGradient;
    if (status === "OCCUPIED_PAST_DUE") return occupiedPastDue;
    if (status === "OCCUPIED_LOCKED_OUT") return occupiedLockedOut;
    return occupiedGradient;
  }
  return (
    <Card sx={{ maxWidth: 120, height: 35 }}>
      <CardContent
        sx={{
          background: getBackground(status),
          display: "flex",
          alignItems: "center",
          height: "100%",
          justifyContent: "center",
          flex: 1,
          padding: "0 6px!important",
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: "#f0f0f0",
            fontWeight: "bold",
          }}
          align="center"
        >
          {status.replace("OCCUPIED_", "").split("_").join(" ")}
        </Typography>
      </CardContent>
    </Card>
  );
}

/* 
  VACANT
  OCCUPIED
*/
